import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Skeleton } from '../../src';
import { Row } from './helpers';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "skeleton"
    }}>{`Skeleton`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Skeleton from 'mfcl/Skeleton'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`The Skeleton component allows you to a build out placeholder Skeleton layouts.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Skeleton} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Skeleton width=\"25%\" />\n<Skeleton width=\"50%\" />\n<Skeleton width=\"75%\" />\n<Skeleton />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Skeleton,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Skeleton width="25%" mdxType="Skeleton" />
  <Skeleton width="50%" mdxType="Skeleton" />
  <Skeleton width="75%" mdxType="Skeleton" />
  <Skeleton mdxType="Skeleton" />
    </Playground>
    <h2 {...{
      "id": "type"
    }}>{`Type`}</h2>
    <Playground __position={2} __code={'<Skeleton type=\"text\" />\n<Skeleton type=\"image\" />\n<Skeleton type=\"button\" />\n<Skeleton type=\"button-secondary\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Skeleton,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Skeleton type="text" mdxType="Skeleton" />
  <Skeleton type="image" mdxType="Skeleton" />
  <Skeleton type="button" mdxType="Skeleton" />
  <Skeleton type="button-secondary" mdxType="Skeleton" />
    </Playground>
    <h2 {...{
      "id": "widthheight-units"
    }}>{`Width/Height Units`}</h2>
    <Playground __position={3} __code={'<Skeleton type=\"text\" width={25} />\n<Skeleton type=\"text\" width=\"25\" />\n<Skeleton type=\"text\" width=\"25px\" />\n<Skeleton type=\"text\" width=\"25%\" />\n<Skeleton type=\"text\" width=\"10em\" />\n<br />\n<div style={{ height: \'200px\' }}>\n  <Skeleton type=\"text\" height={10} />\n  <Skeleton type=\"text\" height=\"10\" />\n  <Skeleton type=\"text\" height=\"10px\" />\n  <Skeleton type=\"text\" height=\"10%\" />\n  <Skeleton type=\"text\" height=\"5em\" />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Skeleton,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Skeleton type="text" width={25} mdxType="Skeleton" />
  <Skeleton type="text" width="25" mdxType="Skeleton" />
  <Skeleton type="text" width="25px" mdxType="Skeleton" />
  <Skeleton type="text" width="25%" mdxType="Skeleton" />
  <Skeleton type="text" width="10em" mdxType="Skeleton" />
  <br />
  <div style={{
        height: '200px'
      }}>
    <Skeleton type="text" height={10} mdxType="Skeleton" />
    <Skeleton type="text" height="10" mdxType="Skeleton" />
    <Skeleton type="text" height="10px" mdxType="Skeleton" />
    <Skeleton type="text" height="10%" mdxType="Skeleton" />
    <Skeleton type="text" height="5em" mdxType="Skeleton" />
  </div>
    </Playground>
    <h2 {...{
      "id": "custom-layouts"
    }}>{`Custom Layouts`}</h2>
    <Playground __position={4} __code={'<Row>\n  <div style={{ width: \'30%\' }}>\n    <Skeleton type=\"image\" width=\"100%\" />\n    <Skeleton width=\"100%\" />\n    <Skeleton width=\"66%\" />\n  </div>\n  <div style={{ width: \'30%\' }}>\n    <Skeleton type=\"image\" width=\"100%\" />\n    <Skeleton width=\"100%\" />\n    <Skeleton width=\"66%\" />\n  </div>\n  <div style={{ width: \'30%\' }}>\n    <Skeleton type=\"image\" width=\"100%\" />\n    <Skeleton width=\"100%\" />\n    <Skeleton width=\"66%\" />\n  </div>\n</Row>\n<Skeleton type=\"text\" height=\"10px\" style={{ marginBottom: \'15px\' }} />\n<Row justifyContent=\"center\">\n  <Skeleton\n    circle\n    type=\"text\"\n    width=\"50px\"\n    height=\"60px\"\n    style={{ marginRight: \'15px\' }}\n  />\n  <Skeleton width=\"66%\" />\n  <Skeleton type=\"image\" width=\"100%\" />\n  <Skeleton type=\"button\" width=\"100%\" />\n</Row>\n<Skeleton type=\"text\" height=\"10px\" style={{ marginBottom: \'15px\' }} />\n<div style={{ display: \'flex\', justifyContent: \'space-between\' }}>\n  <Skeleton type=\"image\" width=\"33%\" style={{ marginRight: \'15px\' }} />\n  <div style={{ width: \'66%\' }}>\n    <Row justifyContent=\"space-between\">\n      <Skeleton\n        circle\n        type=\"text\"\n        width=\"35px\"\n        height=\"35px\"\n        style={{ marginRight: \'15px\' }}\n      />\n      <Skeleton type=\"text\" width=\"60%\" />\n    </Row>\n    <Skeleton type=\"text\" width=\"75%\" />\n    <Skeleton type=\"text\" width=\"33%\" />\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Skeleton,
      Row,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <div style={{
          width: '30%'
        }}>
      <Skeleton type="image" width="100%" mdxType="Skeleton" />
      <Skeleton width="100%" mdxType="Skeleton" />
      <Skeleton width="66%" mdxType="Skeleton" />
    </div>
    <div style={{
          width: '30%'
        }}>
      <Skeleton type="image" width="100%" mdxType="Skeleton" />
      <Skeleton width="100%" mdxType="Skeleton" />
      <Skeleton width="66%" mdxType="Skeleton" />
    </div>
    <div style={{
          width: '30%'
        }}>
      <Skeleton type="image" width="100%" mdxType="Skeleton" />
      <Skeleton width="100%" mdxType="Skeleton" />
      <Skeleton width="66%" mdxType="Skeleton" />
    </div>
  </Row>
  <Skeleton type="text" height="10px" style={{
        marginBottom: '15px'
      }} mdxType="Skeleton" />
  <Row justifyContent="center" mdxType="Row">
    <Skeleton circle type="text" width="50px" height="60px" style={{
          marginRight: '15px'
        }} mdxType="Skeleton" />
    <Skeleton width="66%" mdxType="Skeleton" />
    <Skeleton type="image" width="100%" mdxType="Skeleton" />
    <Skeleton type="button" width="100%" mdxType="Skeleton" />
  </Row>
  <Skeleton type="text" height="10px" style={{
        marginBottom: '15px'
      }} mdxType="Skeleton" />
  <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
    <Skeleton type="image" width="33%" style={{
          marginRight: '15px'
        }} mdxType="Skeleton" />
    <div style={{
          width: '66%'
        }}>
      <Row justifyContent="space-between" mdxType="Row">
        <Skeleton circle type="text" width="35px" height="35px" style={{
              marginRight: '15px'
            }} mdxType="Skeleton" />
        <Skeleton type="text" width="60%" mdxType="Skeleton" />
      </Row>
      <Skeleton type="text" width="75%" mdxType="Skeleton" />
      <Skeleton type="text" width="33%" mdxType="Skeleton" />
    </div>
  </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      